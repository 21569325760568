"use client";
import React, { ChangeEvent, useEffect, useState } from "react";
import Address from "../../components/Checkout/Address";
import { getCountry } from "../../services/woocommerceServices/Checkout";
import { CartItem, Coupon } from "../../types";
import Layout from "../../components/layout/Layout";
import Checkbox from "../../components/common/InputFields/Checkbox";
import { Formik, useFormik } from "formik";
import addressValidationSchema from "../../utils/ValidationSchema/addressValidation";
import { HeadFC, navigate, useStaticQuery, graphql, Script } from "gatsby";
import OrderDetails from "../../components/Checkout/OrderDetails";
import TextArea from "../../components/common/InputFields/TextArea";
import ShippingMethods from "../../components/Checkout/ShippingMethods";
import { useDispatch, useSelector } from "react-redux";
import { rootReducer } from "../../store";
import {
  createOrder,
  getAvailablePayments,
} from "../../services/woocommerceServices/Orders";
import "./Checkout.css";
import {
  createWooCommerceCustomer,
  getWooCommerceCustomer,
  updateUserCart,
} from "../../services/woocommerceServices/Customer";
import { addUserData, setStateList, stateToggler } from "../../store/userSlice";
import UseJWTChecker from "../../utils/Custom-hook/useJWTChecker";
import PageLoader from "../../components/common/Loader/PageLoader";
import { Product } from "../../types";
import { getGroupProduct } from "../../services/woocommerceServices/Products";
import ProductCarousel from "../../components/Products/ProductCarousel";
import GatewayList from "../../components/Checkout/GarewayList";
import UpsellModal from "../../components/UpsellModal";
import { useLocation } from "@reach/router";

const Checkout = () => {
  const dispatch = useDispatch();
  const { countries, states }: any = useSelector(
    (state: rootReducer) => state.user
  );
  const [records, setRecords] = useState<Product[]>([]);
  const { couponLoading, discount } = useSelector(
    (state: rootReducer) => state.cart
  );
  const [loading, setLoading] = useState(false);
  const [stateAutoFill, setStateAutoFill] = useState("");
  const [stateList, setStates] = useState<any[]>([]);
  const [shippingMethodError, setShippingMethodError] = useState("");
  const userData: any = useSelector(
    (state: rootReducer) => state.user.userDetails
  );
  // const userDetails = JWTChecker();
  const { isLoggedIn, email, userId } = UseJWTChecker();
  const total = useSelector((state: rootReducer) => state.cart.total);
  const shippingMethod: any = useSelector(
    (state: rootReducer) => state.cart.shippingMethod
  );
  const cartItems: CartItem[] = useSelector((state: any) => state.cart.items);

  const initialValues = {
    first_name: "",
    last_name: "",
    company_name: "",
    country_name: "",
    address_1: "",
    address_2: "",
    town: "",
    state: "",
    zip_code: "",
    phone: "",
    email: "",
  };

  const [shipChecked, setShipChecked] = useState(false);
  const [newsChecked, setNewsChecked] = useState(false);
  const [orderNotes, setOrderNotes] = useState("");

  // GATEWAY STATES
  const [allGateway, setAllGateway] = useState([]);
  const [postPayment, setPostPayment] = useState(false);
  const [payToken, setPayToken] = useState(null);
  const [orderId, setOrderId] = useState(null);

  const getCustomer = async () => {
    try {
      const customer: any = await getWooCommerceCustomer(email as string);
      dispatch(addUserData(customer?.data?.[0]));
    } catch (error) {
      // console.error(error, "<<-- Error in getting customer.");
    }
  };

  useEffect(() => {
    if (cartItems.length > 0) {
      fetchUpSellProducts();
    } else {
      setRecords([]);
    }
  }, [cartItems]);

  const fetchUpSellProducts = async () => {
    const groupedProductIds = cartItems?.map((product) => product.productId);

    const data: any = await getGroupProduct(groupedProductIds);

    if (data && data?.grouped_product?.length > 0) {
      const groupedUpsellIds = [
        ...new Set(
          data?.grouped_product
            ?.map((product: any) => product.upsell_ids)
            .flat()
        ),
      ];
      const filteredUniqueUpsellIds = groupedUpsellIds.filter(
        (item: any) => !groupedProductIds.includes(item)
      );

      if (filteredUniqueUpsellIds?.length > 0) {
        const upsellProductDetails: any = await getGroupProduct(
          filteredUniqueUpsellIds
        );
        // console.log(upsellProductDetails, "upsellProductDetails");
        setRecords(upsellProductDetails?.grouped_product);
      }
    }
  };

  // The settings which enables guest checkout
  // if (!isLoggedIn) {
  // 	navigate("/my-account");
  // }

  useEffect(() => {
    if (isLoggedIn && email) {
      getCustomer();
    }
  }, [isLoggedIn, email]);

  useEffect(() => {
    if (cartItems.length === 0) {
      navigate("/");
    }
  }, [cartItems]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.name === "shipping_address") {
      setShipChecked(e.target.checked);
    } else {
      setNewsChecked(e.target.checked);
    }
  };

  const billingFormik = useFormik({
    initialValues,
    validationSchema: addressValidationSchema,
    onSubmit: (values, { resetForm }) => {
      // resetForm();
    },
  });

  const shippingFormik = useFormik({
    initialValues,
    validationSchema: addressValidationSchema,
    onSubmit: (values, { resetForm }) => {
      // resetForm();
    },
  });

  useEffect(() => {
    if (email) {
      // If user is logged in and has email then we need to show email.
      billingFormik.setFieldValue("email", email);
      shippingFormik.setFieldValue("email", email);
    }
    if (!isLoggedIn || !userData?.billing?.address_1) {
      console.log("0======> ");
      // Handle default country setup if not logged in or no billing info
      const defaultCountry = countries.find(
        (country: any) => country.value === "US"
      );
      if (defaultCountry && !billingFormik.values.country_name) {
        console.log("1======> ");
        billingFormik.setFieldValue("country", defaultCountry);
        billingFormik.setFieldValue("country_name", defaultCountry.value);

        shippingFormik.setFieldValue("country", defaultCountry);
        shippingFormik.setFieldValue("country_name", defaultCountry.value);

        getCountry("US").then((countryData: any) => {
          setStates(countryData.states);
          dispatch(setStateList(countryData.states));
        });
      }
      return;
    }
    console.log(userData, "======>>> USER DATA");
    // Extract billing and shipping data
    const { billing, shipping } = userData;

    // Define fields and map form values
    const mapFields = (data: any) =>
      data && {
        first_name: data.first_name || "",
        last_name: data.last_name || "",
        company_name: data.company_name || "",
        address_1: data.address_1 || "",
        address_2: data.address_2 || "",
        zip_code: data.postcode || "",
        phone: data.phone || "",
        email: email || "",
        town: data.city || "",
      };

    // // Set form values for billing and shipping
    // billingFormik.setValues((prevValues) => ({
    //   ...prevValues,
    //   ...mapFields(billing),
    // }));

    shippingFormik.setValues((prevValues) => ({
      ...prevValues,
      ...mapFields(shipping),
    }));

    // Handle country and state updates
    const updateCountryAndState = async (
      countryCode: string,
      stateCode: string,
      formikType: any
    ) => {
      const selectedCountry = countries.find(
        (country: any) => country.value === countryCode
      );
      if (selectedCountry) {
        formikType.setFieldValue("country", selectedCountry);
        formikType.setFieldValue("country_name", selectedCountry.value);

        const countryData: any = await getCountry(countryCode);
        dispatch(setStateList(countryData.states));

        const selectedState = countryData.states.find(
          (state: any) => state.code === stateCode
        );
        if (selectedState) {
          formikType.setFieldValue("state", selectedState.code);
          formikType.setFieldValue("stateObj", {
            label: selectedState.name,
            value: selectedState.code,
          });
        }
      }
    };

    updateCountryAndState(billing.country, billing.state, billingFormik);
    updateCountryAndState(shipping.country, shipping.state, shippingFormik);
    console.log("ssss");
  }, [isLoggedIn, userData, countries, email]);

  // BILLING INFORMATION PRE-FILL ON PADELOAD
  useEffect(() => {
    console.log("===================");
    if (email) {
      console.log("IS EMAIL", email);
      // If user is logged in and has email then we need to show email.
      billingFormik.setFieldValue("email", email);
    }
    if (!isLoggedIn || !userData?.billing?.address_1) {
      console.log("first");
      // Handle default country setup if not logged in or no billing info
      const defaultCountry = countries.find(
        (country: any) => country.value === "US"
      );
      if (defaultCountry && !billingFormik.values.country_name) {
        billingFormik.setFieldValue("country", defaultCountry);
        billingFormik.setFieldValue("country_name", defaultCountry.value);

        getCountry("US").then((countryData: any) => {
          setStates(countryData.states);
          dispatch(setStateList(countryData.states));
        });
      }
      return;
    }
    const { billing } = userData;

    const mapFields = (data: any) =>
      data && {
        first_name: data.first_name || "",
        last_name: data.last_name || "",
        company_name: data.company_name || "",
        address_1: data.address_1 || "",
        address_2: data.address_2 || "",
        zip_code: data.postcode || "",
        phone: data.phone || "",
        email: email || "",
        town: data.city || "",
      };

    // Set form values for billing and shipping
    billingFormik.setValues((prevValues) => ({
      ...prevValues,
      ...mapFields(billing),
    }));
  }, [isLoggedIn, userData]);

  const coupons: Partial<Coupon[]> = useSelector(
    (state: rootReducer) => state.cart.coupon
  );

  const placeOrder = async () => {
    // Create a URL object
    const urlObj = new URL(location.href);

    // Get the search parameters
    const params = new URLSearchParams(urlObj.search);

    // Build the meta_data array
    const meta_data = Array.from(params.entries()).map(([key, value]) => ({
      key: key,
      value: decodeURIComponent(value.replace(/%22/g, "")), // Decode and remove quotes
    }));

    const discountMeta = {
      key: "discount",
      value: discount,
    };

    meta_data.push(discountMeta);

    if (!shippingMethod?.method_id) {
      setShippingMethodError("No shipping method seletected!");
      return;
    } else {
      setShippingMethodError("");
      const billing = billingFormik.values;
      const shipping = shippingFormik.values;
      const listItem: any[] = [];

      billingFormik.handleSubmit();

      const billingErrors = await billingFormik.validateForm();

      if (Object.keys(billingErrors).length > 0) {
        // Object.keys(billingErrors).forEach((key) => {
        // 	billingFormik.touched[key] = true;
        // });
        window.scrollTo(0, 0);
        return;
      }

      if (shipChecked) {
        shippingFormik.handleSubmit();

        const shippingErrors = await shippingFormik.validateForm();

        if (Object.keys(shippingErrors).length > 0) {
          // Object.keys(shippingErrors).forEach((key) => {
          // 	shippingFormik.touched[key] = true;
          // });
          window.scrollTo(0, 1200);

          return;
        }
      }
      cartItems.forEach((data: any) => {
        // console.log(data, "<<-- data");
        // Main product payload
        if (data?.linkedProducts && data?.linkedProducts?.length > 0) {
          const mainProduct: any = {
            product_id: data.productId,
            quantity: data.quantity,
            total: (data.price * data.quantity).toFixed(2),
            meta_data: [
              {
                key: "bundled_items",
                value: data.linkedProducts
                  .map(
                    (linkedProduct: any) =>
                      `${linkedProduct.productId}-${linkedProduct.variant.id}`
                  )
                  .join(","),
              },
              {
                key: "bundled_items_data",
                value: data.linkedProducts.map((linkedProduct: any) => ({
                  product_id: linkedProduct.productId,
                  variation_id: linkedProduct.variant.id,
                })),
              },
            ],
          };

          listItem.push(mainProduct);

          // Linked products payload
          data.linkedProducts.forEach((linkedProduct: any) => {
            listItem.push({
              product_id: linkedProduct.productId,
              variation_id: linkedProduct.variant.id,
              meta_data: [
                {
                  key: "bundled_by",
                  value: data.productId,
                },
              ],
              quantity: data.quantity,
              subtotal: "0.0", // Assuming subtotal is 0 as per provided example
              total: "0.0",
            });
          });
        } else {
          let price = Number(data?.variant?.price ?? data?.price);

          let totalPrice = Number(price) * data.quantity;

          if (data?.couponDiscount && data?.couponDiscount !== null) {
            const deal = data.couponDiscount;
            if (deal.discount_type === "percent") {
              totalPrice -= price * (deal.discount_value / 100) * deal.quantity;
            } else if (deal.discount_type === "override") {
              totalPrice -= price - deal.discount_value;
            } else if (deal.discount_type === "fixed") {
              totalPrice -= deal.discount_value * deal.quantity; // Apply the fixed discount
            }
          }
          totalPrice = Number(totalPrice.toFixed(2));
          // console.log(totalPrice, "<<-- totalPrice");
          console.log(data, "00000");
          listItem.push({
            product_id: data.variant ? data.variant.id : data.productId,
            quantity: data.quantity,
            subtotal: totalPrice.toFixed(2),
            total: totalPrice.toFixed(2),
            meta_data: [
              { key: "original_price", value: Number(price) * data.quantity },
            ],
          });
        }
      });
      // console.log(listItem, "listItem");
      // console.log(cartItems)

      let payload: any = {
        customer_id: userData?.id || 0,
        payment_method: "bacs",
        payment_method_title: "Direct Bank Transfer",
        set_paid: false,
        billing: {
          ...billing,
          city: billing.town,
          country: billing.country_name,
          postcode: billing.zip_code,
        },
        shipping: shipChecked
          ? {
            ...shipping,
            city: shipping.town,
            country: shipping.country_name,
            postcode: billing.zip_code,
          }
          : {
            ...billing,
            city: billing.town,
            country: billing.country_name,
            postcode: billing.zip_code,
          },
        line_items: listItem,
        shipping_lines: [shippingMethod],
        meta_data: meta_data,
      };
      setLoading(true);
      // PROCESS CUPON
      // console.log("coupons--->",coupons)
      const arr: any = [];

      coupons.map((cData: any, index: any) => {
        arr.push({ code: cData.code });
      });

      if (arr.length > 0) {
        payload.coupon_lines = arr;
      }

      try {
        const resp: any = await createOrder(payload);
        setPayToken(resp?.gateway_list?.token);
        setAllGateway(resp.gateway_list?.paymentStatus);
        setPostPayment(true);
        setOrderId(resp.orderStatus.id);
      } catch (error) {
        console.error(error, "<<-- Error in create order: ");
        setLoading(false);
      }
    }
  };

  const handleAddressChange = (e: any, section: string) => {
    if (section === "billing") {
      billingFormik.setFieldValue("country_name", e.value);
      billingFormik.setFieldValue("country", e);
      billingFormik.setFieldValue("state", "");
      billingFormik.setFieldValue("stateObj", "");
    } else {
      shippingFormik.setFieldValue("country_name", e.value);
      shippingFormik.setFieldValue("country", e);
      shippingFormik.setFieldValue("state", "");
      shippingFormik.setFieldValue("stateObj", "");
    }
  };

  const handleStateChange = (e: any, section: string) => {
    if (section === "billing") {
      billingFormik.setFieldValue("state", e.value);
      billingFormik.setFieldValue("stateObj", e);
      shippingFormik.setFieldError("state", "");
    } else {
      shippingFormik.setFieldValue("state", e.value);
      shippingFormik.setFieldValue("stateObj", e);
      shippingFormik.setFieldError("state", "");
    }
  };

  useEffect(() => {
    // Handle changes to form fields (including autofilled data)
    const onInputChange = (e: any) => {
      const { name, value } = e.target;

      if (name === "state" && stateList?.length > 0) {
        const stateIndex = stateList.findIndex(
          (state: any) => (state?.label || state?.name) === value
        );
        const autoFillState = stateList[stateIndex];

        const stateObj = {
          label: autoFillState.name || autoFillState.label,
          value: autoFillState.value,
        };

        billingFormik.setFieldValue("state", value);
        billingFormik.setFieldValue("stateObj", stateObj);
      } else if (name === "state") {
        setStateAutoFill(value);
      }
    };

    // Attach event listeners to form fields
    document.querySelectorAll("input").forEach((input) => {
      input.addEventListener("input", onInputChange);
    });

    return () => {
      document.querySelectorAll("input").forEach((input) => {
        input.removeEventListener("input", onInputChange);
      });
    };
  }, [stateList]);

  useEffect(() => {
    if (stateAutoFill) {
      const autoFillData = stateList.find(
        (state: any) => (state?.label || state?.name) === stateAutoFill
      );

      const stateObj = {
        label: autoFillData.name || autoFillData.label,
        value: autoFillData.value,
      };

      billingFormik.setFieldValue("state", stateAutoFill);
      billingFormik.setFieldValue("stateObj", stateObj);
    }
  }, [stateAutoFill, stateList]);

  return (
    <Layout>
      <section className="px-[15px] md:px-[27px] xl:px-[88px] lg:px-[50px]  pb-[30px] contain lg:pt-[60px] pt-0">
        <h1 className="text-[40px] font-sofiaBold font-bold text-black ml-[-4px] md:block hidden">
          Checkout
        </h1>
        <div
          className={`flex justify-between  lg:gap-[50px] md:gap-5 md:flex-row flex-col  ${records?.length > 0 ? "md:mb-0 mb-0" : " md:mb-[80px] mb-[50px]"
            }`}
        >
          {/* {loading && <PageLoader /> } */}
          {/* {!loading && ( */}

          <div className="addresses lg:w-[60%] md:w-1/2 w-full md:order-1 order-2 mt-[30px]">
            <h2 className="font-sofiaBold font-bold md:text-[28px] text-[25px]">
              Billing Address
            </h2>
            <div className="flex  items-start w-full">
              <Address
                block={postPayment}
                showShippingEmailField={true}
                formik={billingFormik}
                handleCountryNameChange={(values) =>
                  handleAddressChange(values, "billing")
                }
                handleStateNameChange={(e: any) =>
                  handleStateChange(e, "billing")
                }
              />
            </div>
            <div className="flex flex-col justify-start bg-bg-grey py-4 rounded-[6px] border px-2 mb-6 gap-3 mt-3">
              <Checkbox
                disabled={postPayment}
                label="Ship to a different address?"
                id="shipping_address"
                name="shipping_address"
                checked={shipChecked}
                onChange={(e) => handleChange(e)}
                className="peer cursor-pointer appearance-none relative h-4 w-4  border border-black transition-all  checked:bg-white rounded-[5px] !mb-0"
              />
            </div>

            {shipChecked ? (
              <Address
                block={postPayment}
                showShippingEmailField={true}
                formik={shippingFormik}
                handleCountryNameChange={(values) =>
                  handleAddressChange(values, "shipping")
                }
                handleStateNameChange={(e: any) =>
                  handleStateChange(e, "shipping")
                }
              />
            ) : (
              <></>
            )}

            <div>
              <TextArea
                label="Order Notes"
                name="order_notes"
                id="order_notes"
                placeholder="Notes about your order, e.g. special notes for delivery."
                onChange={(e) => setOrderNotes(e.target.value)}
                value={orderNotes}
              />
            </div>

            {/* Card details Start */}
            {/* <CardDetails/> */}
            {/* Card details End  */}
            {/* Shipping method */}
            <div className="shipping_method">
              <ShippingMethods
                shippingMethodError={shippingMethodError}
                setError={setShippingMethodError}
              />
            </div>
            {!postPayment && (
              <button
                type="submit"
                className={`${couponLoading ? "bg-gray-300" : "bg-black hover:bg-primary"
                  } h-[54px] px-4 w-full rounded-[51px] font-sofiaBold font-bold lg:mt-6 mt-4 text-[20px] text-white`}
                onClick={placeOrder}
                disabled={couponLoading || loading ? true : false}
              >
                {!loading ? "CONTINUE" : "Please wait..."}
              </button>
            )}

            {/* CARD details  */}
            {/* <CardDetails /> */}
            {allGateway?.length > 0 && (
              <GatewayList
                data={allGateway}
                token={payToken}
                orderId={orderId}
              />
            )}
            {/* CARD details  */}
          </div>
          {/* )} */}
          <OrderDetails />
        </div>
      </section>

      {/* Upsell Products */}
      {records?.length > 0 ? (
        <>
          <UpsellModal productList={records} />
          {/* <div className="md:px-[27px] lg:px-[27px]">
            <ProductCarousel
              isShowAddToCartBtn={true}
              productList={records}
              title={
                wpUpsellData?.wp?.upsellTitle != ""
                  ? wpUpsellData?.wp?.upsellTitle
                  : "Recommended for you"
              }
            />
          </div> */}
        </>
      ) : (
        <></>
      )}
    </Layout>
  );
};

export const query = graphql`
	query {
		wp {
			isSeoEnabled
			isSearchEngineEnabled
		}
	}
`;

export const Head: HeadFC<any> = ({ data }) => {
  const location = useLocation();
  const isSearchEnabled = data.wp.isSearchEngineEnabled;

  return (
    <>
      <title>Checkout</title>
      <link
        rel="canonical"
        href={`${location.origin}${location.pathname}`}
      ></link>
      <meta name="robots" content={!isSearchEnabled ? "noindex, nofollow" : "index, follow"} />
    </>
  );
};

export default Checkout;
