import React, { FC, useState } from "react";
import Button from "../common/Buttons/Button";
import { finalPaymentProcess } from "../../utils/helper";
import { navigate } from "gatsby";

interface Props {
  data: any;
  token: any;
  orderId: any;
}

const GatewayList: FC<Props> = ({ data, token, orderId }) => {
  const [selectedPaymentType, setSelectedPaymentType] = useState("");
  const [selectedObj, setSelectedObj] = useState<any>();
  const [loading, setLoading] = useState(false);

  const handlePaymentChange = (e: any, data: any) => {
    setSelectedPaymentType(e.target.value);
    setSelectedObj(data);
    // console.log(selectedObj);
  };

  const payHandeler = async () => {
    setLoading(true);
    const finalStatus = await finalPaymentProcess(
      token,
      selectedObj,
      Number(orderId)
    );
    console.log(finalStatus, "finalStatusfinalStatus")
    // console.log(finalStatus, "====finalStatus");
    if (typeof finalStatus.api_res.data.result == "boolean") {
      navigate(`/thankyou/?tokenpay=${finalStatus.token}&idorder=${orderId}`);
      setLoading(true);
    } else {
      navigate(finalStatus.api_res.data.result);
      setLoading(true);
      // throw new Error("ERROR");
    }
  };

  return (
    <div className=" mx-auto py-4">
      <div className="relative flex md:flex-row flex-col md:items-center justify-between mb-4">
        <h2 className="text-2xl font-sofiaBold font-bold ">
          Select a Payment Method
        </h2>

        {!selectedPaymentType && (
          <div className="error-msg !relative !top-0 ">
            Please choose at least one payment method.
          </div>
        )}
      </div>

      {data.map((method: any, index: any) => (
        <div key={index} className="mb-4 border-b pb-4">
          <h3 className="text-xl font-sofiaMedium font-bold mb-4 ">
            {method.type_title}{" "}
          </h3>
          <div className="space-y-2">
            {method.available_payments.map(
              (payment: any, paymentIndex: any) => (
                <>
                  <label
                    key={paymentIndex}
                    className="block p-3 bg-gray-100 rounded-lg cursor-pointer hover:bg-gray-200"
                  >
                    <input
                      type="radio"
                      name="paymentMethod"
                      value={payment.payment_code}
                      checked={selectedPaymentType === payment.payment_code}
                      onChange={(e) => {
                        handlePaymentChange(e, payment);
                      }}
                      className="mr-2"
                    />
                    {payment.payment_title} -{" "}
                    {Number(payment.total_payment_amount).toFixed(2)}{" "}
                    {/* Additional charge :{payment.additional_charge.toFixed(2)} */}
                    ({payment.currency})
                    <br />
                  </label>
                  {selectedObj?.payment_code == payment?.payment_code &&
                  selectedObj ? (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: selectedObj?.payment_description,
                      }}
                    ></div>
                  ) : (
                    ""
                  )}
                </>
              )
            )}
          </div>
          {/* {selectedObj.payment_description} */}
        </div>
      ))}

      {/* <p className="text-gray-600 my-4 font-sofiaRegular">
        Selected Payment: <strong>{selectedPaymentType || "None"}</strong>
      </p> */}
      <button
        className=" bg-black hover:bg-primary text-white text-[20px] font-sofiaBold font-bold rounded-[40px]   w-full  py-3 px-4 focus:outline-none cursor-pointer "
        type="button"
        onClick={payHandeler}
        disabled={selectedPaymentType ? false : true}
      >
        {!loading ? "Complete Order" : "Please wait..."}
      </button>
    </div>
  );
};

export default GatewayList;
